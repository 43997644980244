import React from "react";
import "./Main.css";
import Pic from "../../Media/hero-banner.png";
export default function Main() {
  function scroll(id) {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  function startup() {
    const target_1 = document.querySelector("#Main_div-1");
    const target_2 = document.querySelector("#banner_img");
    target_1.style.transform = "translateX(0px)";
    target_1.style.opacity = "1";
    setTimeout(() => {
      target_2.style.opacity = "1";
      target_2.style.animation = "rotate 4s ease ";
    }, 500);
  }
  React.useEffect(() => {
    setTimeout(() => {
      startup();
    }, 1000);
  }, []);
  return (
    <div id="Main">
      <div id="Main_div-1">
        <h2 id="main_h2">A Digital Marketing Agency</h2>
        <h1 id="main_h1">We Design Interfaces That Users Love</h1>
        <p id="main_p">
          Elevate Your Brand's Online Presence with Our Exceptional Digital
          Marketing Solutions. Let Us Be Your Strategic Ally on the Path to
          Digital Success.
        </p>
        <button
          id="Apply_btn"
          onClick={() => {
            scroll("#Contact_div");
          }}
        >
          Contact Us
        </button>
      </div>
      <img src={Pic} id="banner_img" alt="" loading="lazy" />
    </div>
  );
}
