import React from 'react'
import Home from "./Component/Home/Home"
import Loading from "./Component/Loading/Loading"
function App() {
  const [component, set_component] = React.useState(<Loading />)
  function starting() {
    setTimeout(() => {
      set_component(<Home />)
    }, 2700)
  }
  React.useEffect(() => {
    starting()
  }, [])
  return (
    <>
      {component}
    </>
  );

}

export default App;

