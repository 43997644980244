import React, { useEffect, useRef } from "react";
import "./Testimony.css";
import Test_card from "./Test_card";
import pic1 from "../../Media/Namanpath.jpg";
import pic2 from "../../Media/cadtabs.png";
import pic3 from "../../Media/Amitoptic.png";
export default function Testimony() {
  function start_up() {
    const about = document.querySelector("#Testimony");
    about.style.opacity = "1";
    about.style.transform = "translateX(0px)";
  }
  const content_ = [
    {
      name: "",
      dest: "Namanpath",
      con: "The best Video Editing and Post designing services By Make A Mine",
      Pic: pic1,
    },
    {
      name: "Ankit Yadav ",
      dest: "Founder , Amit Opticals",
      con: "Make A Mine has exceptional team and communication is very smooth. Best for ad campaigns . Kudos to the team !!",
      Pic: pic3,
    },
    {
      name: "En Hemant Srivastava ",
      dest: "Ceo Cadtabs",
      con: "I Strongly Recommend Make A Mine , Tried their Web Designing and ad campaign services and I must say , they are the best.",
      Pic: pic2,
    },
  ];

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          start_up();
        }
      });
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const target = document.querySelector("#Testimony");
    if (target) {
      observer.observe(target);
    }
  }, []);

  return (
    <div id="Testimony">
      <div id="feature_head">
        <h1>Customer Reviews</h1>
        <div id="feature_head_border"></div>
      </div>
      <div id="testimony_div">
        <div id="test_card">
          <Test_card cont={content_[0]} id_={`test_card${0}`} />
          <Test_card cont={content_[1]} id_={`test_card${1}`} />
          <Test_card cont={content_[2]} id_={`test_card${2}`} />
        </div>
      </div>
    </div>
  );
}
