import React from "react";
import Logo from "../../Media/Logo.png";
import "./Header.css";
export default function Header() {
  const [status, set_status] = React.useState(false);
  async function menu() {
    const target = document.querySelector(".Menu");
    const line_1 = document.querySelector("#Menu_line-1");
    const line_2 = document.querySelector("#Menu_line-2");
    const line_3 = document.querySelector("#Menu_line-3");

    const target_text = document.querySelectorAll(".drop-item");
    if (status === false) {
      target.style.display = "flex";
      await new Promise((resolve) => setTimeout(resolve, 0));
      target.style.opacity = "1";
      target.style.width = "250px";
      setTimeout(() => {
        target_text.forEach((element) => {
          element.style.opacity = "1";
        });
      }, 500);
      line_2.style.transform = "translateX(-100px)";
      line_2.style.opacity = "0";
      line_1.style.transform = "rotate(135deg) translateY(-11px)";
      line_3.style.margin = "0px";
      line_1.style.margin = "0px";
      line_3.style.transform = "rotate(45deg) translateY(-12px)";
      set_status(true);
    } else if (status === true) {
      target_text.forEach((element) => {
        element.style.opacity = "0";
      });
      setTimeout(() => {
        target.style.opacity = "0";
        target.style.width = "0px";
      }, 300);
      setTimeout(() => {
        target.style.display = "none";
      }, 800);
      line_1.style.transform = "rotate(0deg) translateY(0px)";
      line_3.style.margin = "4px";
      line_1.style.margin = "4px";
      line_3.style.transform = "rotate(0deg) translateY(0px)";
      line_2.style.transform = "translateX(0px)";
      line_2.style.opacity = "1";
      set_status(false);
    }
  }
  function scroll(id) {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  function drop_scroll(id) {
    const line_1 = document.querySelector("#Menu_line-1");
    const line_2 = document.querySelector("#Menu_line-2");
    const line_3 = document.querySelector("#Menu_line-3");

    const target = document.querySelector(".Menu");
    const target_text = document.querySelectorAll(".drop-item");
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      target_text.forEach((element) => {
        element.style.opacity = "0";
      });
      setTimeout(() => {
        target.style.opacity = "0";
        target.style.width = "0px";
      }, 300);
      setTimeout(() => {
        target.style.display = "none";
      }, 800);
      line_1.style.transform = "rotate(0deg) translateY(0px)";
      line_3.style.margin = "4px";
      line_1.style.margin = "4px";
      line_3.style.transform = "rotate(0deg) translateY(0px)";
      line_2.style.transform = "translateX(0px)";
      line_2.style.opacity = "1";
      set_status(false);
    }
  }
  async function handleScroll() {
    const target_div = document.querySelector("#header_div");
    const target_head = document.querySelector("#heading");
    const target_text = document.querySelectorAll(".nav-item");
    const Logo = document.querySelector("#Logo");
    const btn = document.querySelector("#Apply_btn");
    const link = document.querySelectorAll("#link_under");
    const icon = document.querySelectorAll(".Menu_line");

    btn.addEventListener("mouseenter", () => {
      btn.style.color = "white";
    });
    btn.addEventListener("mouseleave", () => {
      if (window.scrollY > 100) {
        btn.style.color = "black";
      } else {
        btn.style.color = "white";
      }
    });
    if (window.scrollY > 100) {
      icon.forEach((element) => {
        element.style.backgroundColor = "black";
      });
      target_div.style.backgroundColor = "rgb(255, 255, 255)";
      target_div.style.boxShadow = "0 2px 30px hsla(0, 0%, 0%, 0.1)";
      target_head.style.color = "black";
      target_text.forEach((item) => {
        item.style.color = "black";
      });
      link.forEach((element) => {
        element.style.backgroundColor = "black";
      });
      Logo.style.opacity = "0";
      btn.style.borderColor = "black";
      btn.style.color = "black";
      Logo.style.display = "none";
    } else {
      Logo.style.display = "block";
      icon.forEach((element) => {
        element.style.backgroundColor = "white";
      });
      target_div.style.backgroundColor = "transparent";
      target_div.style.boxShadow = "none";
      target_head.style.color = "white";
      target_text.forEach((item) => {
        item.style.color = "white";
      });
      link.forEach((element) => {
        element.style.backgroundColor = "white";
      });
      Logo.style.opacity = "1";
      btn.style.borderColor = "white";
      btn.style.color = "white";
    }
  }
  window.addEventListener("scroll", handleScroll);
  return (
    <>
      <div id="header_div">
        <div id="Header">
          <div id="heading">
            <img src={Logo} alt="" id="Logo" />
            <h1>Make A Mine</h1>
          </div>
          <div id="navbar">
            <li
              className="nav-item"
              onClick={() => {
                scroll("#Home");
              }}
            >
              Home <span id="link_under"></span>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                scroll("#about");
              }}
            >
              About <span id="link_under"></span>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                scroll("#feature");
              }}
            >
              Services <span id="link_under"></span>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                scroll("#feature_div");
              }}
            >
              Features <span id="link_under"></span>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                scroll("#Testimony");
              }}
            >
              Reviews<span id="link_under"></span>
            </li>
            <a href="https://forms.gle/rsnhXGjxptckpm9W6" target="_blank">
              <button id="Apply_btn">Free Strategy Call</button>
            </a>
          </div>
          <div
            id="Menu_btn_div"
            onClick={() => {
              menu();
            }}
          >
            <div className="Menu_line" id="Menu_line-1"></div>
            <div className="Menu_line" id="Menu_line-2"></div>
            <div className="Menu_line" id="Menu_line-3"></div>
          </div>
        </div>
        <div className="Menu">
          <div id="drop_heading">
            {/* <img src={Logo} alt="" id="Logo" /> */}
            <h1 className="drop-item">Make A Mine</h1>
          </div>
          <li
            className="drop-item"
            onClick={() => {
              drop_scroll("#Home");
            }}
          >
            Home{" "}
          </li>
          <li
            className="drop-item"
            onClick={() => {
              drop_scroll("#about");
            }}
          >
            About{" "}
          </li>
          <li
            className="drop-item"
            onClick={() => {
              drop_scroll("#feature");
            }}
          >
            Services{" "}
          </li>
          <li
            className="drop-item"
            onClick={() => {
              drop_scroll("#feature_div");
            }}
          >
            Features{" "}
          </li>
          <li
            className="drop-item"
            onClick={() => {
              drop_scroll("#Testimony");
            }}
          >
            Reviews
          </li>
          <li
            className="drop-item"
            onClick={() => {
              drop_scroll("#Contact_div");
            }}
          >
            Contact Us
          </li>
        </div>
      </div>
    </>
  );
}
