import React from "react";
import "./Specialization.css";
import { AiOutlineGlobal as Webdev } from "react-icons/ai";
import { GiPencil as Blog } from "react-icons/gi";
import { RiAdvertisementLine as Add } from "react-icons/ri";
import {
  BsGraphUpArrow as SEO,
  BsFillShareFill as Share,
  BsYoutube as Youtube,
} from "react-icons/bs";
export default function Specialization() {
  function start_up() {
    const about = document.querySelector("#feature");
    about.style.opacity = "1";
    about.style.transform = "translateX(0px)";
  }
  React.useEffect(() => {
    function handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          start_up();
        }
      });
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const target = document.querySelector("#feature");

    if (target) {
      observer.observe(target);
    }
  }, []);
  return (
    <div id="feature">
      <div id="feature_head">
        <h1>Our Specializations</h1>
        <div id="feature_head_border"></div>
      </div>
      <div id="feature_section">
        <div class="card">
          <div class="first-content">
            <Webdev id="feature_icon" />
            <span className="feature_card_heading">Web Development</span>
          </div>
          <div class="second-content">
            <span>
              Crafting success begins with a clear idea. We analyze your vision,
              goals, & audience, shaping innovative strategies that align with
              your objectives.
            </span>
          </div>
        </div>

        <div class="card">
          <div class="first-content">
            <SEO id="feature_icon" />
            <span className="feature_card_heading">
              Search Engine Optimization
            </span>
          </div>
          <div class="second-content">
            <span>
              Unlock the true potential of your website with our meticulous SEO
              strategies. We analyze, optimize, and fine-tune your website to
              improve its search engine rankings, ensuring your target audience
              finds you effortlessly.
            </span>
          </div>
        </div>

        <div class="card">
          <div class="first-content">
            <Add id="feature_icon" />
            <span className="feature_card_heading">Advertisement</span>
          </div>
          <div class="second-content">
            <span>
              <p>Facebook, Instagram and Google</p>
              Empower your brand with Facebook, Instagram, Google, and YouTube
              advertising. Reach millions, boost sales, and maximize visibility.
            </span>
          </div>
        </div>
        <div class="card">
          <div class="first-content">
            <Blog id="feature_icon" />
            <span className="feature_card_heading">Blogging</span>
          </div>
          <div class="second-content">
            <span>
              Fuel your digital presence with engaging, informative, and
              relevant blog content. Our expert writers curate content that
              resonates with your audience, establishing you as an authority in
              your industry.
            </span>
          </div>
        </div>
        <div class="card">
          <div class="first-content">
            <Share id="feature_icon" />
            <span className="feature_card_heading">
              Social Media Management
            </span>
          </div>
          <div class="second-content">
            <span>
              {" "}
              <p>Instagram, LinkedIn and more</p>
              Amplify your social media impact with our expert management
              services. We devise captivating strategies for heightened
              engagement, follower growth, and extensive reach.
            </span>
          </div>
        </div>
        <div class="card">
          <div class="first-content">
            <Youtube id="feature_icon" />
            <span className="feature_card_heading">Youtube Management</span>
          </div>
          <div class="second-content">
            <span>
              We optimize your content, develop engaging strategies, assist with
              video production, boost audience growth, provide data-driven
              insights, and guide you through monetization..
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
