import React from 'react'
import './card.css'

export default function Test_card({ cont, id_ }) {
    return (
        <>
            <div id={id_} className='inner_Card_Test'>
                <img src={cont.Pic} alt="" id="test_img" />
                <h2>{cont.name}</h2>
                <h5>{cont.dest}</h5>
                <p>{cont.con}</p>
            </div>
        </>
    )
}
