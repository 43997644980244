import React, { useState } from 'react'
import "./Loading.css"
export default function Loading() {

    function startup() {
        const target = document.querySelector("#Loading")
        setTimeout(() => {
            target.style.opacity = "1"
        }, 10)
        setTimeout(() => {
            target.style.opacity = "0"
        }, 2500)
    }
    React.useEffect(() => {
        startup();
    }, [])
    return (
        <div id="Loading">
            <div class="loader">
                <div class="box box-1">
                    <div class="side-left"></div>
                    <div class="side-right"></div>
                    <div class="side-top"></div>
                </div>
                <div class="box box-2">
                    <div class="side-left"></div>
                    <div class="side-right"></div>
                    <div class="side-top"></div>
                </div>
                <div class="box box-3">
                    <div class="side-left"></div>
                    <div class="side-right"></div>
                    <div class="side-top"></div>
                </div>
                <div class="box box-4">
                    <div class="side-left"></div>
                    <div class="side-right"></div>
                    <div class="side-top"></div>
                </div>
            </div>
            <h1 id="loading_heading"></h1>
        </div>
    )
}
