import React from "react";
import About from "../About/About";
import "./Home.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Specialization from "../Specialization/Specialization";
import Feature from "../Feature/Feature";
import Footer from "../Footer/Footer";
import Testimony from "../Testimony/Testimony";
import ContactUs from "../ContactUs/ContactUs";
export default function Mainpage() {
  function startup() {
    setTimeout(() => {
      const target = document.querySelector("#Home");
      target.classList.add("opacity-change");
      target.style.opacity = "1";
    }, 100);
  }
  React.useEffect(() => {
    startup();
  }, []);
  return (
    <>
      <Header />
      <div id="Home">
        <Main />
      </div>
      <About />
      <Specialization />
      <Feature />
      <Testimony />
      <ContactUs />
      <Footer />
    </>
  );
}
