import React from "react";
import "./Footer.css";
import { FaFacebookF as Facebook } from "react-icons/fa";
import { BsInstagram as Instagram, BsYoutube as Youtube } from "react-icons/bs";
import { BiLogoLinkedin as LinkedIn } from "react-icons/bi";

export default function Footer() {
  function scroll(id) {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  return (
    <div id="footer">
      <div class="footer_card">
        <div id="inner_footer">
          <div className="footer_inner_card" id="facebook_tag">
            <p id="footer_icon_tag">Facebook</p>
            <a
              class="socialContainer"
              id="Facebook"
              href="https://www.facebook.com/makeamineofficial/"
              target="_blank"
            >
              <Facebook className="footer_icon" />
            </a>
          </div>
          <div className="footer_inner_card" id="Instagram_tag">
            <p id="footer_icon_tag">Instagram</p>
            <a
              class="socialContainer"
              id="Instagram"
              href="https://instagram.com/_makeamineofficial"
              target="_blank"
            >
              <Instagram className="footer_icon" />
            </a>
          </div>
        </div>
        <div id="inner_footer">
          <div className="footer_inner_card" id="LinkedIn_tag">
            <p id="footer_icon_tag">LinkedIn</p>
            <a
              class="socialContainer"
              id="LinkedIn"
              href="https://www.linkedin.com/company/make-a-mine/"
              target="_blank"
            >
              <LinkedIn className="footer_icon" />
            </a>
          </div>
          <div className="footer_inner_card" id="Youtube_tag">
            <p id="footer_icon_tag">Youtube</p>
            <a
              class="socialContainer"
              id="Youtube"
              href="https://youtube.com/@MakeAMine?si=bZY2ajkRvr5f7HT7"
              target="_blank"
            >
              <Youtube className="footer_icon" />
            </a>
          </div>
        </div>
      </div>
      <p
        id="footer_head"
        onClick={() => {
          scroll("#Main");
        }}
      >
        © 2022 Make A Mine. All Right Reserved
      </p>
      <div id="dev_section">
        <h5>Developed By Damanjeet Singh</h5>
        <div>
          <b>
            <a href="https://github.com/DSB2004">Github</a>
          </b>
          <b>
            <a href="https://www.linkedin.com/in/damanjeet-singh-8b879b295">
              LinkedIn
            </a>
          </b>
        </div>
      </div>
    </div>
  );
}
