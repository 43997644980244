import React from 'react'
import { GiPaintBrush as Brush } from "react-icons/gi"
import { AiOutlineCode } from "react-icons/ai"
import { FaBug, FaLightbulb as Idea } from "react-icons/fa"
import "./Feature.css"
import Pic from "../../Media/feautres-banner.png"
export default function Feature() {
    function start_up() {
        const about = document.querySelector("#feature_div")
        about.style.opacity = "1"
        about.style.transform = "translateX(0px)"
    }
    React.useEffect(() => {
        function handleIntersection(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    start_up()
                }
            });
        }

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        const target = document.querySelector('#feature_div'); 

        if (target) {
            observer.observe(target);
        }
    }, []);
    return (
        <div id="feature_div">
            <div id="feature_head">
                <h1>Our Features</h1>
                <div id="feature_head_border">
                </div>
            </div>
            <div id="feat_content">
                <div id="feat_container_">
                    <div id="inner_feat_container">
                        <div id="inner_feat">
                            <div >
                                <Idea id="feat_icon" />
                            </div>
                            <div id="feat_innercontent">
                                <h2>Idea Analysis</h2>
                                <p>Crafting success begins with a clear idea. We analyze your vision, goals, & audience, shaping innovative strategies that align with your objectives.</p>
                            </div>
                        </div>
                    </div>
                    <div id="inner_feat_container">
                        <div id="inner_feat">
                            <div >
                                <Brush id="feat_icon" />
                            </div>
                            <div id="feat_innercontent">
                                <h2>Designing</h2>
                                <p>Visual impact matters. Our designers create captivating visuals, blending creativity with user-centric design principles for seamless and immersive experiences.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={Pic} alt="" id="feature_center_img" />
                <div id="feat_container_">
                    <div id="inner_feat_container">
                        <div id="inner_feat">
                            <div >
                                < AiOutlineCode id="feat_icon" />
                            </div>
                            <div id="feat_innercontent">
                                <h2>Development</h2>
                                <p>From concept to reality. Our experts use cutting-edge tech to build robust solutions tailored to your needs, ensuring user-friendliness and innovation.</p>
                            </div>
                        </div>
                    </div>
                    <div id="inner_feat_container">
                        <div id="inner_feat">
                            <div >
                                <FaBug id="feat_icon" />
                            </div>
                            <div id="feat_innercontent">
                                <h2>Testing & Launching</h2>
                                <p>Quality assurance is key. Rigorous testing ensures flawless functionality across devices. With excellence assured, we guide you through a seamless launch, propelling your digital journey.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
