import React from 'react'
import "./About.css"
import banner_pic from "../../Media/about-banner.png"
export default function About() {
    function start_up() {
        const about = document.querySelector("#about")
        about.style.opacity = "1"
        about.style.transform = "translateX(0px)"
    }
    React.useEffect(() => {
        function handleIntersection(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    start_up()
                }
            });
        }

        const options = {
            root: null, 
            rootMargin: '0px',
            threshold: 0,
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        const target = document.querySelector('#about'); // Replace with your div's ID

        if (target) {
            observer.observe(target);
        }
    }, []);
    return (
        <div id="about">
            <img src={banner_pic} alt="" id="banner_img_about" loading="lazy" />
            <div id="about_content">
                <h1 id="about_head">Why Our Agency</h1>
                <div id="about_head_border"></div>
                <p id="about_p">At Make A Mine, we specialize in delivering top-notch digital marketing solutions tailored to elevate your brand's online presence and drive exceptional results. With our comprehensive range of services, we empower businesses to stand out in the digital landscape and achieve their goals.</p>
                <p id="about_p">Let us be your strategic ally on your journey to digital success.</p>
                <div id="about_container">
                    <div className="about_container_content">
                        <h1>10+</h1>
                        <h4>Happy Clients</h4>
                    </div>
                    <div className="about_container_content">
                        <h1>300+</h1>
                        <h4>Blogs written</h4>
                    </div>
                    <div className="about_container_content">
                        <h1>20+</h1>
                        <h4>Strategy Call</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
