import React from "react";
import "./ContactUs.css";
import { FaLocationDot as Location, FaPhone as Phone } from "react-icons/fa6";
import { AiFillMail as Mail } from "react-icons/ai";
export default function ContactUs() {
  return (
    <div id="Contact_div">
      <div id="contact_Details">
        <div className="contact_info">
          <Mail id="icon" />
          <h4>info@makeamine.com</h4>
        </div>
        <div className="contact_info">
          <Mail id="icon" />
          <h4>varun@makeamine.com</h4>
        </div>
        <div className="contact_info">
          <Phone id="icon" />
          <h4> 8766247447</h4>
        </div>
        <div className="contact_info">
          <Location id="icon" />
          <h4>Delhi India</h4>
        </div>
      </div>
    </div>
  );
}
